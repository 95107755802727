import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import imageOrangePlus from 'images/plus-icon.png';
import imageOrangeMinus from 'images/minus-icon.png';
import imageOrangeQ from 'images/q-icon.png';

// For more customizations
// https://github.com/springload/react-accessible-accordion

function FaqsSet({ faqData, className }) {
  return (
    <Accordion
      className={`container-accordion-faqs ${className}`}
      allowZeroExpanded
    >
      {faqData.map(item => {
        return (
          <AccordionItem
            id={item.id}
            key={item.id}
            className="accordion-item-faq"
          >
            <Row>
              <Col xs={2} sm={1} className="single-col">
                <div className="accordion-q" role="presentation">
                  <img src={imageOrangeQ} alt="" width="30px" />
                </div>
              </Col>
              <Col xs={10} sm={11} className="multi-col">
                <AccordionItemHeading className="accordion-heading">
                  <AccordionItemButton className="accordion-button">
                    <div
                      className="text-bold faq-question"
                      dangerouslySetInnerHTML={{ __html: item.question }}
                    />
                    <div className="accordion-plus" role="presentation">
                      <img
                        src={imageOrangePlus}
                        alt="Green Plus Icon"
                        width="30px"
                      />
                    </div>
                    <div className="accordion-minus" role="presentation">
                      <img
                        src={imageOrangeMinus}
                        alt="Green Minus Icon"
                        width="30px"
                      />
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
              </Col>
            </Row>
            <Row>
              <Col xs={2} sm={1} className="single-col" />
              <Col xs={10} sm={11}>
                <AccordionItemPanel>
                  <div
                    className="faq-answer"
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </AccordionItemPanel>
              </Col>
            </Row>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}

export default FaqsSet;

FaqsSet.propTypes = {
  faqData: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};
