import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import imgEndoLogo from '../../../assets/images/endo-corporate-logo.png';
import './support-end.scss';

function SupportEndMessage() {
  return (
    <div>
      <div className='availability-tactics__content'>
        <div className="body-content-container">
          <div className="body-content gutter-all site-wrapper">
            <main className="site-content">
              <p>
                <a href="https://www.endodocuments.com/nascobal/pi" target="_blank"
                  rel="noopener noreferrer">Full Prescribing Information</a>
              </p>
              <p>Endo USA, Inc. has announced the decision to cease the production and sale of Endo’s NASCOBAL<sup>®</sup> (Cyanocobalamin, USP) Nasal Spray.</p>
              <br />
              <p style={{paddingBottom: '0'}}>
                In addition, Endo has announced the decision to cease the Nutrition Direct™ program and the distribution of BariActiv<sup>®</sup> supplements, including:
              </p>
              <ul>
                <li>BariActiv<sup>®</sup> Multivitamin, with or without Iron</li>
                <li>BariActiv<sup>®</sup> Calcium + D<sub>3</sub> & Magnesium</li>
              </ul>
              <p>These decisions are effective immediately.</p>
              <p>Thank you for your attention to this matter and for giving us the opportunity to provide you with vitamins and supplements for the last 8 years.</p>
              <p>
                <strong>For Patients:</strong><br />
                Please reach out to your healthcare provider to discuss alternative treatment and supplement options.
              </p>
              <p>
                <strong>For Medical Inquiries:</strong><br />
                Please contact Endo Medical Information at 1-800-462-3636 from 9 AM to 8 PM ET Monday through Thursday, or from 9 AM to 6 PM ET Friday.
              </p>
            </main>
            <footer>
              <Row className="endo-footer-logo-block">
                <Col xs={12} md={3}>
                  <a
                    href="https://www.endo.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={imgEndoLogo} className="footer-logo" />
                  </a>
                </Col>
                <Col xs={12} md={6} className="rx-block">
                  <p>
                    © 2024 Endo, Inc. or one of its affiliates. All rights reserved.
                    <br />
                    <strong>NS-05843/May 2024</strong>
                    &nbsp; <a className='text-black' href="https://www.endo.com/" target="_blank" rel="noopener noreferrer">www.endo.com</a>&nbsp; <nobr>1-800-462-ENDO (3636)</nobr>
                  </p>
                </Col>
              </Row>
            </footer>
          </div >
        </div >
      </div >
    </div >
  )
}

export default SupportEndMessage