import React from 'react';
import PropTypes from 'prop-types';

function Callout({ className, children, ...props }) {
  return (
    <div {...props} className={`callout ${className}`}>
      <div className="callout-content">{children}</div>
    </div>
  );
}

export default Callout;

Callout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
