import React from 'react';
import 'scss/default-theme/theme.scss';
import SupportEndMessage from '../support-end-message';

export default function Layout() {

  return (
    <>
      <SupportEndMessage />
    </>
  );
}


